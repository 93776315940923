<template>
  <div id="PessoaContainer" v-bind:style="{ 'background-color':COR_PRINCIPAL }">

    <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">
      <!-- TITULO DA PAGINA -->
      <v-toolbar-title class="text-white title-page body-1 mt-1 ml-3 w-100 mb-0">
        <router-link
        :to="{ name: 'PessoaConsulta' }"
        class="nav-link"
        aria-current="page"
        >
          <v-btn icon dark color="grey" class="btn-icon mr-1">
            <v-icon color="#84A0CD" class="icon-action"> mdi-chevron-left </v-icon>
          </v-btn>
        </router-link> 
        Cadastro de Pessoas
      </v-toolbar-title>
      <!-- FIM TITULO DA PAGINA -->

      <div class="d-flex d-sm-none mt-n14 mb-6 pa-4 pt-5" >
          <v-avatar 
            v-if="store_Pessoa.pessoa_edicao.pessoa_foto"
            class="mr-4"
            size="50">
            <v-img :src="store_Pessoa.pessoa_edicao.pessoa_foto"> </v-img>
          </v-avatar>
          <v-avatar
            v-else
            class="mr-4"
            color="#D0D0D0"
            rounded="false"
            size="50"
          >
            <span color="#274ABB" class="headline">
              {{ nomeIniciais(store_Pessoa.pessoa_edicao.pessoa_nome) }}
            </span>
          </v-avatar>

        <div class="d-flex flex-column align-center mt-n1">
          <div class="d-flex flex-column">
            <span class="body-2 font-weight-medium text-white">{{ store_Pessoa.pessoa_edicao.pessoa_nome }}</span>
            <span class="text-opacity body-2 text-white">{{ store_Pessoa.pessoa_edicao.pessoa_email }}</span>
            <span class="text-opacity body-2 text-white">{{ store_Pessoa.pessoa_edicao.fone_celular1 }}</span>
          </div>

        </div>
      </div>

      <!-- TABS MOBILE -->
      <div class="d-block d-sm-none mt-n6 mb-1" tabindex="-1">
        <v-tabs
          v-if="isMobile"
          v-model="itemSelecionado"
          background-color="transparent"
          dark
          class="tab-mobile mr-2 ml-2 mt-n3 pa-0 mb-0"
          center-active
          slider-size="3"
          tabindex="-1"
          slider-color="#D0D0D0"
          active-class="active"
          color="white"
        >
          <v-tab
            v-for="item in abas_mobile"
            :key="item"
            class="text-none"
            tabindex="-1"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </div>
      <!-- FIM TABS MOBILE -->
    </v-toolbar-title>
<!-- {{store_Pessoa.pessoa_edicao.pessoa_foto}} -->
    <v-container class="container rounded-lg mt-n10 pa-0">
      <v-card elevation="0" class="card d-flex flex-column pb-0">
        <v-card-text class="card-content my-0 py-0 px-2">
          <v-row class="d-none d-sm-block wrapper-top rounded-tl-lg my-0 py-0 px-1">
            <!-- COLUNA TOPO ------------------------------->
            <v-col class="col-top rounded-t-lg ma-0 pa-0">
              <v-sheet
              class="cabecalho d-flex rounded-t-lg mx-auto pa-4 pb-4"
              >
                <v-avatar 
                  v-if="store_Pessoa.pessoa_edicao.pessoa_foto"
                  size="72">
                  <v-img :src="store_Pessoa.pessoa_edicao.pessoa_foto"> </v-img>
                </v-avatar>
                <v-avatar
                  v-else
                  color="#D0D0D0"
                  rounded="false"
                  size="72"
                >
                  <span color="#274ABB" class="headline">
                    {{ nomeIniciais(store_Pessoa.pessoa_edicao.pessoa_nome) }}
                  </span>
                </v-avatar>

                <v-col class="pa-0 pl-4">
                  <div class="d-flex flex-column">
                    <span class="header-text body-2 font-weight-medium mr-4">Nome: <span class="font-weight-bold body-1" v-bind:style="{ 'color':COR_SUBTITULO }">{{ store_Pessoa.pessoa_edicao.pessoa_nome }}</span></span>
                    <span class="header-text mr-4">E-mail: <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">{{ store_Pessoa.pessoa_edicao.pessoa_email }}</span></span>
                    <span class="header-text mr-4">Telefone: <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">{{ store_Pessoa.pessoa_edicao.fone_celular1 }}</span></span>
                  </div>
                </v-col>
              </v-sheet>

              <v-divider class="divisor-horizontal"></v-divider>

            </v-col>
            <!-- FIM COLUNA TOPO ------------------------------->
          </v-row>

          <v-row class="wrapper-bottom my-0 py-0 px-1">
            <!-- COLUNA ESQUERDA ------------------------------->
            <v-col class="col-esquerda shrink d-none d-sm-flex flex-column rounded-tl-lg rounded-bl-lg pa-0">
              <v-list 
                v-show="expand"
                class="list rounded-bl-lg overflow-y-scroll pt-0" dense>

                <v-list-item-group
                  v-model="itemSelecionado"
                  active-class="bg-active"
                  mandatory>
                  <template v-for="[icon, text, quant] in store_Pessoa.abas"
                  link
                  class="px-3">

                    <!-- SEPARADOR ------------------------------->
                    <v-divider 
                    v-if="text == 'separador'"
                    :key="icon"
                    class="my-1"
                    ></v-divider>
                    <!-- FIM SEPARADOR ------------------------------->

                    <v-list-item v-else
                    :key="icon"
                    :disabled="store_Pessoa.acao != 'C'"
                    >
                      <v-list-item-icon v-if="text != 'separador'" class="mr-2">
                        <v-icon class="size-icon">{{ icon }}</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content v-if="text != 'separador'" class="mr-n2">
                        <v-badge
                        icon="mdi-check"
                        :value="quant"
                        color="green accent-9"
                        offset-x="20"
                        offset-y="18">
                        </v-badge>

                        <v-badge
                        :content="quant"
                        :value="quant"
                        color="blue accent-1"
                        offset-x="20"
                        offset-y="18" >
                        </v-badge>
                        <v-list-item-title class="new-font">{{ text }}</v-list-item-title>
                      </v-list-item-content>

                    </v-list-item>
                  </template>
                </v-list-item-group>  
              </v-list>    

              <v-btn
              class="btn-expanded ma-0 pa-0"
              @click="expand = !expand"
              :style="`${expand ? '' : 'right: -10px!important;'}`"
              >
                <v-icon
                v-if="expand == false"
                class="size-icon-expanded ma-0 pa-0">mdi-chevron-right</v-icon>

                <v-icon
                v-else 
                class="size-icon-expanded ma-0 pa-0">mdi-chevron-left</v-icon>
              </v-btn>   
            </v-col>
            <!-- FIM COLUNA ESQUERDA ------------------------------->

            <v-divider class="divisor-vertical" vertical ></v-divider>

            <!-- COLUNA DIREITA ------------------------------->
            <v-col v-if="itemSelecionado == 0" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-3">
              <PessoaCadastro />  
            </v-col>

            <v-col v-if="itemSelecionado == 1" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-3">
              <!-- <PessoaDocumentos />   -->
              <GedDocumentos
                :cod_pessoa_aux="this.$route.params.cod_pessoa"
                :paramsTipoDocumento="paramsTipoDocumento"
                :paramBusca="{ cod_pessoa : this.$route.params.cod_pessoa }"
                :statusDocumento="'Aprovado'"
              />
            </v-col>
            <!-- FIM COLUNA DIREITA ------------------------------->

          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { nomeIniciais } from "../../services/funcoes"
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SECUNDARIA_2, COR_SUBTITULO, COR_BACKGROUND_2 } from "../../services/constantes";
import PessoaCadastro from "./PessoaCadastro.vue";
// import PessoaDocumentos from "./PessoaDocumentos.vue";
import GedDocumentos from "../Ged/GedDocumentos.vue";
import store_Pessoa from "./store_Pessoa"

export default {

  components: {
    PessoaCadastro,
    GedDocumentos,
    // PessoaDocumentos,
  },

  props: ['IsMobile'],

  data() {
    return { 
      store_Pessoa      : store_Pessoa,

      nomeIniciais      :nomeIniciais,

      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,
      COR_SECUNDARIA    : COR_SECUNDARIA,
      COR_SECUNDARIA_2  : COR_SECUNDARIA_2,
      COR_BACKGROUND_2  : COR_BACKGROUND_2,
      paramsTipoDocumento : {},

      expand          : true,
      tab             : null,
      itemSelecionado : 0,

      dados: {
        nome      : "Fernando Garcia",
        telefone  : "(16) 99999-9999",
        email     : "john@vuetifyjs.com",
      },

      abas_mobile: [
        'Dados Básicos', 'Documentos',
      ],

    }
  },

  created() {
    // console.log('CREATED')
  },

  destroyed() {
    // console.log('DESTROYED')
  },

  async mounted() {
    
  },
  watch: {
    'store_Pessoa.pessoa_edicao'(){
      let tipo_documentos = this.store_Pessoa.pessoa_edicao.pessoa_fisica_juridica == 'Física' ? String('Documentos Pessoais') : String('Documentos da Empresa')
      this.paramsTipoDocumento = {
        tipo_cadastro: 'pessoa', tipo_cadastro_descricao: tipo_documentos
      }
    }

  },

  computed: {
    isMobile() {
      // console.log("IsMobile", this.IsMobile);
      if (this.IsMobile)
        return this.IsMobile
      else  
        return this.$vuetify.breakpoint.name === "xs";
    },
  },

};
</script>

<style scoped lang="scss">
   html{
    overflow: hidden;
  }
.title-page {
  width: 600px !important;
  height: 80px;
  font-family: "Open Sans", sans-serif!important;
  font-size: 17px!important;
}
.v-application--wrap{
  min-height: 107vh;
}

@media(max-width: 599px) {
  .title-page {
    width: 100%!important;
  }

  .text-opacity {
    opacity: 0.7;
  }
}

@media(max-width: 375px) {
  .tab-mobile {
    margin-left: -44px;
  }
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.btn-icon:hover {
  background: rgba(255, 255, 255, 0.1);
}

.btn-icon:hover .icon-action {
  color: #FFF!important;
  opacity: 0.8;
}

.nav-link {
  text-decoration: none;
}

.container {
  max-width: 100%;
  height: calc(100vh - 200px)!important;
}

@media(max-width: 599px) {
  .container {
    max-width: 100% !important;
    height: calc(100vh - 130px)!important;
    margin-top: -4px!important;
  }

  .avatar {
    margin-top: 4px;
  }
}

.card {
  height: 100%!important;
  border-radius: 8px;
  border-bottom-right-radius: 0!important;
  border-bottom-left-radius: 0!important;
  background: var(--COR_SECUNDARIA);
  box-shadow: var(--COR_SOMBRA)!important;
}

.card-content {
  height: 100%!important;
}

.wrapper-top {
  height: 105px!important;
}

.header-text {
  font-family: "Open Sans", sans-serif!important;
  color: #505050;
}

.wrapper-bottom {
  height: calc(100% - 15px)!important;
}

@media(max-width: 599px) {
  .wrapper-bottom {
    width: 100%;
    flex-direction: column!important;
    justify-content: center;
    margin-right: 0px;
    margin-left: 0px;
  }

  .divisor-vertical {
    display: none;
  }

}

.col-top {
  width: 100vw;
}

.col-esquerda {
  max-width: 300px!important;
  height: 100%;
  position: relative;
  background-color: var(--COR_BACKGROUND);
  // background-color: #FCFCFC !important;
  margin-top: 0px;
  padding-top: 2px!important;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.size-icon {
  font-size: 20px;
}

.size-icon-expanded {
  font-size: 15px;
}

.col-direita {
  height: 100%;
  width: 70%;
  background-color: car(--COR_SECUNDARIA);
}

@media(max-width: 599px) {
  .col-direita {
    padding-right: 4px!important;
    padding-left: 0px!important;
    width: 100%;
  }
}

.list {
  background-color: transparent;
  min-width: 220px!important;
  margin-top: -2px;
  color: var(--COR_SUBTITULO);
}

.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #fff!important;
}

.bg-active {
  background-color: var(--COR_PRINCIPAL);
  color: white !important;
}

.cabecalho {
  background-color: var(--COR_BACKGROUND) !important;
}

@media(max-width: 599px) {
  .cabecalho {
    padding-bottom: 10px!important;
  }
}
</style>